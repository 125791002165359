<template>
  <div>
    <h2 class="text-cmain text-center">入力内容の確認</h2>
    <!-- プラン選択 -->
    <b-card no-body class="mb-5 py-3">
      <b-card-body>
        <b-card-title title-tag="h3" class="text-cmain">
          本人性確認書類
        </b-card-title>
        <b-form-group>
          <legend>
            <b-badge variant="caccent">必須</b-badge>
            本人性確認方式
          </legend>
          <b-form-radio
            v-bind:checked="storedData.ekycPlan"
            name="ekyc-plan"
            value="1"
            class="box-large"
            v-bind:disabled="true"
            >公的個人認証
            <p>マイナンバーカードのICチップの情報を読み取ります。</p>
          </b-form-radio>
          <b-form-radio
            v-bind:checked="storedData.ekycPlan"
            name="ekyc-plan"
            value="2"
            class="box-large"
            v-bind:disabled="true"
            >eKYC「ホ」方式
            <p>下記の写真付き身分証とセルフィーを提出します。</p>
            <ul>
              <li>運転免許証</li>
              <li>パスポート</li>
              <li>マイナンバーカード表面</li>
              <li>住民基本台帳カード</li>
              <li>在留カード</li>
              <li>特別永住者証明書</li>
              <li>外国人登録証明書</li>
              <li>運転経歴証明書</li>
            </ul>
          </b-form-radio>
        </b-form-group>
      </b-card-body>
    </b-card>
    <b-card no-body class="mb-5 py-3">
      <b-card-body>
        <b-card-title title-tag="h3" class="text-cmain">
          個人情報
        </b-card-title>
        <b-form-row v-if="storedData.fieldRequired">
          <b-col cols="6">
            <base-input
              v-bind:value="storedData.personalInfo.surname"
              label="姓"
              id="surname"
              v-bind:required="true"
              v-bind:disabled="true"
            >
            </base-input>
          </b-col>
          <b-col cols="6">
            <base-input
              v-bind:value="storedData.personalInfo.firstname"
              label="名"
              id="firstname"
              v-bind:required="true"
              v-bind:disabled="true"
            >
            </base-input>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="6">
            <base-input
              v-bind:value="storedData.personalInfo.surnameKana"
              label="姓よみがな"
              id="surname-kana"
              v-bind:required="true"
              v-bind:disabled="true"
            >
            </base-input>
          </b-col>
          <b-col cols="6">
            <base-input
              v-bind:value="storedData.personalInfo.firstnameKana"
              label="名よみがな"
              id="firstname-kana"
              v-bind:required="true"
              v-bind:disabled="true"
            >
            </base-input>
          </b-col>
        </b-form-row>
        <base-input
          v-if="storedData.fieldRequired"
          v-bind:value="storedData.personalInfo.dateOfBirth"
          label="生年月日（西暦）"
          id="date-of-birth"
          type="date"
          v-bind:required="true"
          v-bind:disabled="true"
        >
        </base-input>
        <base-input
          v-if="storedData.fieldRequired"
          v-bind:value="storedData.personalInfo.genderText"
          label="性別"
          id="gender"
          v-bind:required="true"
          v-bind:disabled="true"
        >
        </base-input>
        <base-input
          v-if="storedData.fieldRequired"
          v-bind:value="storedData.personalInfo.address1"
          description="現在お住まいの住所の都道府県"
          label="住所1（現住所：都道府県）"
          id="address1"
          v-bind:required="true"
          v-bind:disabled="true"
        >
        </base-input>
        <base-input
          v-if="storedData.fieldRequired"
          v-bind:value="storedData.personalInfo.address2"
          description="現在お住まいの住所の市区町村"
          label="住所2（現住所：市町村区）"
          id="address2"
          v-bind:required="true"
          v-bind:disabled="true"
        >
        </base-input>
        <base-input
          v-if="storedData.fieldRequired"
          v-bind:value="storedData.personalInfo.address3"
          description="現在お住まいの住所の市区町村以降"
          label="住所3（現住所：市町村区以下）"
          id="address3"
          v-bind:required="true"
          v-bind:disabled="true"
        >
        </base-input>
        <base-input
          v-bind:value="storedData.personalInfo.phoneNumber"
          label="連絡先電話番号"
          id="phone-number"
          v-bind:required="true"
          v-bind:disabled="true"
        >
        </base-input>
        <base-input
          v-bind:value="storedData.personalInfo.email"
          label="メールアドレス"
          id="email"
          type="email"
          v-bind:required="true"
          v-bind:disabled="true"
        >
        </base-input>
        <base-input
          v-bind:value="storedData.personalInfo.receptionTypeName"
          label="コイン受取方法"
          id="reception"
          v-bind:required="true"
          v-bind:disabled="true"
        >
        </base-input>
      </b-card-body>
    </b-card>
    <b-card no-body v-if="storedData.fieldRequired" class="mb-5 py-3">
      <b-card-body>
        <b-form-checkbox
          v-bind:checked="storedData.extraAddressRequired"
          class="box-large mb-3"
          name="extra-address"
          v-bind:disabled="true"
        >
          本人性確認書類記載の住所が現住所と異なる
        </b-form-checkbox>
        <base-input
          v-bind:value="storedData.personalInfo.extraAddress1"
          description="本人性確認書類（免許証など）記載の住所の都道府県"
          label="住所1（本人性確認書類記載の住所：都道府県）"
          id="extra-address1"
          v-bind:required="storedData.extraAddressRequired"
          v-bind:disabled="true"
        >
        </base-input>
        <base-input
          v-bind:value="storedData.personalInfo.extraAddress2"
          description="本人性確認書類（免許証など）記載の住所の市区町村"
          label="住所2（本人性確認書類記載の住所：市町村区）"
          id="extra-address2"
          v-bind:required="storedData.extraAddressRequired"
          v-bind:disabled="true"
        >
        </base-input>
        <base-input
          v-bind:value="storedData.personalInfo.extraAddress3"
          description="本人性確認書類（免許証など）記載の住所の市区町村以降"
          label="住所3（本人性確認書類記載の住所：市町村区以下）"
          id="extra-address3"
          v-bind:required="storedData.extraAddressRequired"
          v-bind:disabled="true"
        >
        </base-input>
      </b-card-body>
    </b-card>
    <b-form-row>
      <b-col cols="6" offset-sm="2" sm="4">
        <b-button
          variant="secondary"
          class="w-100"
          v-bind:disabled="isProcessing"
          v-on:click="$router.back()"
        >
          入力内容を修正する
        </b-button>
      </b-col>
      <b-col cols="6" sm="4">
        <b-button
          variant="caccent"
          class="w-100"
          v-bind:disabled="isProcessing"
          v-on:click="sendApplication()"
        >
          口座開設を申請する
        </b-button>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import BaseInput from '@/components/common/BaseInput'
export default {
  props: ['storedData'],
  components: {
    BaseInput,
  },
  data() {
    return {
      isProcessing: false,
    }
  },
  methods: {
    sendApplication() {
      console.log('** send application **')
      this.isProcessing = true
      this.$axios
        .post('/account_opening/application_ekyc', {
          surname: this.storedData.personalInfo.surname,
          firstname: this.storedData.personalInfo.firstname,
          surnameKana: this.storedData.personalInfo.surnameKana,
          firstnameKana: this.storedData.personalInfo.firstnameKana,
          gender: this.storedData.personalInfo.gender,
          dateOfBirth: this.storedData.personalInfo.dateOfBirth,
          phoneNumber: this.storedData.personalInfo.phoneNumber,
          email: this.storedData.personalInfo.email,
          address1: this.storedData.personalInfo.address1,
          address2: this.storedData.personalInfo.address2,
          address3: this.storedData.personalInfo.address3,
          extraAddress1: this.storedData.personalInfo.extraAddress1,
          extraAddress2: this.storedData.personalInfo.extraAddress2,
          extraAddress3: this.storedData.personalInfo.extraAddress3,
          receptionType: this.storedData.personalInfo.receptionType,
          ekycPlan: this.storedData.ekycPlan,
        })
        .then((response) => {
          console.log(response)
          // 書類提出へ
          this.$router.push({
            name: 'submit-documents-ekyc',
            params: {
              applicationId: response.data.applicationId,
              publicId: response.data.publicId,
              planId: response.data.planId,
            },
          })
        })
        .catch((error) => {
          console.log(error)
          if (error.response) {
            console.log(error.response)
            alert(error.response.data.detail)
          } else {
            alert('口座開設申請フォームの送信に失敗しました。')
          }
          this.isProcessing = false
        })
    },
  },
}
</script>

<style scoped>
.card-header {
  background-color: white;
  text-align: center;
}
</style>
